import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  DateField,
  SelectInput
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

const CustomFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='registration'
      reference='registrations'
      sort={{
        field: 'fullName',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        fullName: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='fullName'
        emptyText='clear search'
      />
    </ReferenceInput>
    <ReferenceInput
      source='emails'
      reference='emails'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>
    <SelectInput
      source='status'
      choices={[
        { id: 'pending', name: 'pending' },
        { id: 'complete', name: 'complete' },
        { id: 'failed', name: 'failed' }
      ]}
    />
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List
    {...props}
    filters={<CustomFilter />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
  >
    <Datagrid>
      <ReferenceField
        source='registration'
        reference='registrations'
      >
        <TextField
          source='fullName'
        />
      </ReferenceField>
      <ReferenceField
        source='email'
        reference='emails'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <TextField source='status' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
