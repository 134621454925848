import React from 'react'
import { stringify } from 'query-string'
import {
  Link
} from 'react-admin'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'

const ShowExternalButton = (props) => {
  const defaultValues = {
    [props.reference]: props.record.id
  }
  return (
    <Link
      to={{
        pathname: `/${props.service}`,
        search: stringify({
          filter: JSON.stringify(defaultValues)
        })
      }}
    >
      <Button
        startIcon={<VisibilityIcon />}
      >
        {props.children}
      </Button>
    </Link>
  )
}

export default ShowExternalButton
