import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import get from 'lodash/get'
import FileViewer from 'react-file-viewer'
import {
  useInput,
  Labeled
} from 'react-admin'

const useStyles = makeStyles(theme => ({
  image: {
    maxHeight: 300,
    maxWidth: 300
  },
  buttonEdit: {
    marginBottom: 10
  }
}))

const ImageInput = (props) => {
  const classes = useStyles()
  const [widget, setWidget] = useState({})
  const getInitialValue = props.getInitialValue || get
  const [value, setValue] = useState(getInitialValue(props.record, props.source))
  // const [error, setError] = useState()

  const {
    input
    // meta,
    // isRequired
  } = useInput(props)

  useEffect(() => {
    const widget = window.cloudinary
      .createUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
        },
        (error, result) => {
          if (error) {
            // setError('Something went wrong with cloudinary image uploader.')
            return
          }

          switch (result.event) {
            case 'success': {
              setValue({
                public_id: result.info.public_id,
                width: result.info.width,
                height: result.info.height,
                format: result.info.format,
                resource_type: result.info.resource_type,
                created_at: result.info.created_at,
                tags: result.info.tags,
                bytes: result.info.bytes,
                url: result.info.url,
                secure_url: result.info.secure_url,
                original_filename: result.info.original_filename,
                original_extension: result.info.original_extension,
                path: result.info.path,
                thumbnail_url: result.info.thumbnail_url
              })
              break
            }
            default: {
              break
            }
          }
        })
    setWidget(widget)
  }, [])

  useEffect(() => {
    input.onChange(value)
  }, [value])

  const openWidget = useCallback(() => {
    widget.open()
  }, [widget])

  const content = useMemo(() => {
    if (value && value.secure_url) {
      return (
        <>
          <Button
            onClick={openWidget}
            className={classes.buttonEdit}
          >
            Edit file
          </Button>
          <div><a href={value.secure_url}>{value.secure_url}</a></div>
          <FileViewer
            key={value.secure_url} // needed to force rerender on secure_url change
            fileType={value.secure_url.split('.').pop()}
            filePath={value.secure_url}
          />
        </>
      )
    }

    return (
      <Button
        onClick={openWidget}
      >
        Add file
      </Button>
    )
  }, [value, widget])

  return (
    <Labeled
      {...props}
    >
      {content}
    </Labeled>
  )
}

export default ImageInput
