import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  DateField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label='email'
      source='email'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List
    {...props}
    filters={<CustomFilter />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
  >
    <Datagrid>
      <TextField source='_id' />
      <TextField source='email' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
