import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  DateInput,
  required,
  DateField,
  BooleanInput,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ReferenceField,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  BooleanField
} from 'react-admin'
import CreateExternalButton from '../../elements/CreateExternalButton'
import ShowExternalButton from '../../elements/ShowExternalButton'
import ImageInput from '../../elements/ImageInput'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextField source='registrationPath' />
        <ReferenceField
          source='event'
          reference='events'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <ReferenceField
          source='eventGroup'
          reference='event-groups'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <TextInput source='name' validate={required()} />
        <DateInput source='dateFrom' validate={required()} />
        <DateInput source='dateTo' validate={required()} />
        <TextInput source='localization' />
        <TextInput source='price' />
        <BooleanInput source='open' />
        <BooleanInput source='show' />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
      <FormTab label='registrations' path='registrations'>
        <CreateExternalButton
          reference='eventDate'
          service='registrations'
        >
          Create registration
        </CreateExternalButton>
        <ShowExternalButton
          reference='eventDate'
          service='registrations'
        >
          Show details
        </ShowExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='registrations'
          target='eventDate'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <BooleanField source='confirmed' />
            <TextField source='fullName' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label='files' path='files'>
        <ArrayInput source='files'>
          <SimpleFormIterator>
            <ImageInput
              source=''
              getInitialValue={record => record} // workaround for issue with array input
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='extensions' path='extensions'>
        <ArrayInput source='extensions'>
          <SimpleFormIterator>
            <ReferenceInput
              source='extension'
              reference='extensions'
              sort={{
                field: 'name',
                order: 'ASC'
              }}
              filterToQuery={searchText => ({
                name: {
                  $regex: searchText,
                  $options: 'i'
                }
              })}
              validate={required()}
              label='Extension'
            >
              <AutocompleteInput optionText='name' />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='discounts' path='discounts'>
        <ArrayInput source='discounts'>
          <SimpleFormIterator>
            <ReferenceInput
              source='discount'
              reference='discounts'
              sort={{
                field: 'name',
                order: 'ASC'
              }}
              filterToQuery={searchText => ({
                name: {
                  $regex: searchText,
                  $options: 'i'
                }
              })}
              validate={required()}
              label='Extension'
            >
              <AutocompleteInput optionText='name' />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)
