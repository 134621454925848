import React, { useMemo } from 'react'
import {
  ArrayInput,
  Create,
  DateInput,
  ListButton,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TopToolbar
} from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <TextInput source='name' type='name' validate={required()} />
        <TextInput source='description' multiline />
        <DateInput source='activeFromDate' />
        <DateInput source='activeToDate' />
        <ArrayInput source='options'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' validate={required()} />
            <NumberInput source='price' label='Price' validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
