import React, { useMemo } from 'react'
import {
  BooleanInput,
  Create,
  DateInput,
  ListButton,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  TopToolbar,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <ReferenceInput
          source='event'
          reference='events'
          sort={{
            field: 'name',
            order: 'ASC'
          }}
          filterToQuery={searchText => ({
            name: {
              $regex: searchText,
              $options: 'i'
            }
          })}
          validate={required()}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <TextInput source='name' validate={required()} />
        <DateInput source='dateFrom' validate={required()} />
        <DateInput source='dateTo' validate={required()} />
        <TextInput source='location' />
        <TextInput source='price' />
        <ArrayInput source='extensions'>
          <SimpleFormIterator>
            <ReferenceInput
              source='extension'
              reference='extensions'
              sort={{
                field: 'name',
                order: 'ASC'
              }}
              filterToQuery={searchText => ({
                name: {
                  $regex: searchText,
                  $options: 'i'
                }
              })}
              validate={required()}
              label='Extension'
            >
              <AutocompleteInput optionText='name' />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='discounts'>
          <SimpleFormIterator>
            <ReferenceInput
              source='discount'
              reference='discounts'
              sort={{
                field: 'name',
                order: 'ASC'
              }}
              filterToQuery={searchText => ({
                name: {
                  $regex: searchText,
                  $options: 'i'
                }
              })}
              validate={required()}
              label='Extension'
            >
              <AutocompleteInput optionText='name' />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source='open' />
        <BooleanInput source='show' />
      </SimpleForm>
    </Create>
  )
}
