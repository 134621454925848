import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  UrlField,
  DateField,
  TextInput,
  SelectInput
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

const CustomFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source='registration'
      reference='registrations'
      sort={{
        field: 'fullName',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        fullName: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='fullName'
        emptyText='clear search'
      />
    </ReferenceInput>
    <TextInput
      label='name'
      source='name'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
      alwaysOn
    />
    <TextInput
      label='url'
      source='url'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
      alwaysOn
    />
    <SelectInput
      source='status'
      choices={[
        { id: 'pending', name: 'pending' },
        { id: 'complete', name: 'complete' },
        { id: 'failed', name: 'failed' }
      ]}
    />
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List
    {...props}
    filters={<CustomFilter />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
  >
    <Datagrid>
      <TextField source='name' />
      <TextField source='version' />
      <ReferenceField
        source='registration'
        reference='registrations'
      >
        <TextField
          source='fullName'
        />
      </ReferenceField>
      <UrlField source='url' target='_blank' />
      <TextField source='status' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
