import React from 'react'
import {
  ArrayInput,
  DateField,
  DateInput,
  Edit,
  FormTab,
  ListButton,
  NumberInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextInput source='name' type='name' validate={required()} />
        <TextInput source='description' multiline />
        <DateInput source='activeFromDate' />
        <DateInput source='activeToDate' />
        <ArrayInput source='options'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' validate={required()} />
            <NumberInput source='price' label='Price' validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)
