import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  TextInput,
  DateField,
  ArrayField,
  BooleanInput
} from 'react-admin'
import red from '@material-ui/core/colors/red'
import DateFilters from '../../elements/DateFilters'
import ConfirmButton from './ConfirmButton'

const rowStyle = (record, index) => {
  if (!record.confirmed) {
    return {
      borderLeft: `5px solid ${red[500]}`
    }
  }
}

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput
      source='fullName'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
      alwaysOn
    />
    <ReferenceInput
      source='eventDate'
      reference='event-dates'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>

    <ReferenceInput
      source='event'
      reference='events'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>

    <ReferenceInput
      source='eventGroup'
      reference='event-groups'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>
    <TextInput
      source='pesel'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
    />
    <TextInput
      source='email'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
    />
    <TextInput
      source='phone'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
    />
    <BooleanInput
      source='confirmed'
    />
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List
    {...props}
    filters={<CustomFilter />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
  >
    <Datagrid rowStyle={rowStyle}>
      <ConfirmButton />
      <TextField source='fullName' />
      <ReferenceField
        source='eventDate'
        reference='event-dates'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <ReferenceField
        source='event'
        reference='events'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <ReferenceField
        source='eventGroup'
        reference='event-groups'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <TextField source='pesel' />
      <TextField source='email' />
      <TextField source='phone' />
      <ArrayField source='extensions'>
        <Datagrid>
          <TextField source='name' />
          <TextField source='option' />
          <TextField source='price' />
        </Datagrid>
      </ArrayField>
      <ArrayField source='discounts'>
        <Datagrid>
          <TextField source='name' />
          <TextField source='price' />
        </Datagrid>
      </ArrayField>
      <TextField source='price' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)
