import React from 'react'
import { Admin, Resource } from 'react-admin'
import { restClient, authClient } from 'ra-data-feathers'
import EmailIcon from '@material-ui/icons/Email'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import CheckIcon from '@material-ui/icons/Check'
import DescriptionIcon from '@material-ui/icons/Description'
import CreateIcon from '@material-ui/icons/Create'
import PeopleIcon from '@material-ui/icons/People'
import ExtensionIcon from '@material-ui/icons/Extension'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'

import feathersClient from './client/feathersClient'
import UsersList from './models/users/List'
import UsersEdit from './models/users/Edit'
import UsersCreate from './models/users/Create'
import EvenGroupsList from './models/eventGroups/List'
import EventGroupsCreate from './models/eventGroups/Create'
import EventGroupsEdit from './models/eventGroups/Edit'
import EventDatesList from './models/eventDates/List'
import EventDatesCreate from './models/eventDates/Create'
import EventDatesEdit from './models/eventDates/Edit'
import EventsList from './models/events/List'
import EventsCreate from './models/events/Create'
import EventsEdit from './models/events/Edit'
import ExtensionsList from './models/extensions/List'
import ExtensionsCreate from './models/extensions/Create'
import ExtensionsEdit from './models/extensions/Edit'
import DiscountsList from './models/discounts/List'
import DiscountsCreate from './models/discounts/Create'
import DiscountsEdit from './models/discounts/Edit'
import RegistrationsList from './models/registrations/List'
import RegistrationsCreate from './models/registrations/Create'
import RegistrationsEdit from './models/registrations/Edit'
import AgreementsList from './models/agreements/List'
import AgreementsCreate from './models/agreements/Create'
import AgreementsEdit from './models/agreements/Edit'
import EmailsList from './models/emails/List'
import EmailsCreate from './models/emails/Create'
import EmailsEdit from './models/emails/Edit'
import SignUpEmailsList from './models/signUpEmails/List'
import SignUpEmailsCreate from './models/signUpEmails/Create'
import SignUpEmailsEdit from './models/signUpEmails/Edit'
import ConfirmationEmailsList from './models/confirmationEmails/List'
import ConfirmationEmailsCreate from './models/confirmationEmails/Create'
import ConfirmationEmailsEdit from './models/confirmationEmails/Edit'
import Dashboard from './Dashboard'

const restClientOptions = {
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for update
}

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { // Options included in calls to Feathers client.authenticate
    strategy: 'local' // The authentication strategy Feathers should use
  },
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // The key used to provide the username to Feathers client.authenticate
  redirectTo: '/login' // Redirect to this path if an AUTH_CHECK fails. Uses the react-admin default of '/login' if omitted.
}

const App = () => {
  return (
    <Admin
      title='Admin Panel'
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authClient(feathersClient, authClientOptions)}
      dashboard={Dashboard}
    >
      <Resource
        name='stats'
      />
      <Resource
        name='users'
        icon={PeopleIcon}
        list={UsersList}
        create={UsersCreate}
        edit={UsersEdit}
      />
      <Resource
        name='event-groups'
        list={EvenGroupsList}
        create={EventGroupsCreate}
        edit={EventGroupsEdit}
      />
      <Resource
        name='events'
        list={EventsList}
        create={EventsCreate}
        edit={EventsEdit}
      />
      <Resource
        name='event-dates'
        list={EventDatesList}
        create={EventDatesCreate}
        edit={EventDatesEdit}
      />
      <Resource
        name='extensions'
        icon={ExtensionIcon}
        list={ExtensionsList}
        create={ExtensionsCreate}
        edit={ExtensionsEdit}
      />
      <Resource
        name='discounts'
        icon={MoneyOffIcon}
        list={DiscountsList}
        create={DiscountsCreate}
        edit={DiscountsEdit}
      />
      <Resource
        name='registrations'
        icon={CreateIcon}
        list={RegistrationsList}
        create={RegistrationsCreate}
        edit={RegistrationsEdit}
      />
      <Resource
        name='agreements'
        icon={DescriptionIcon}
        list={AgreementsList}
        create={AgreementsCreate}
        edit={AgreementsEdit}
      />
      <Resource
        name='emails'
        icon={AllInboxIcon}
        list={EmailsList}
        create={EmailsCreate}
        edit={EmailsEdit}
      />
      <Resource
        name='sign-up-emails'
        icon={EmailIcon}
        list={SignUpEmailsList}
        create={SignUpEmailsCreate}
        edit={SignUpEmailsEdit}
      />
      <Resource
        name='confirmation-emails'
        icon={CheckIcon}
        list={ConfirmationEmailsList}
        create={ConfirmationEmailsCreate}
        edit={ConfirmationEmailsEdit}
      />
    </Admin>
  )
}

export default App
