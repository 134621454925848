import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TopToolbar,
  DateField,
  UrlField,
  ReferenceField
} from 'react-admin'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextField source='status' />
        <TextField source='errorMessage' />
        <ReferenceField
          source='registration'
          reference='registrations'
        >
          <TextField
            source='fullName'
          />
        </ReferenceField>
        <UrlField source='url' target='_blank' />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)
