import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  TextInput,
  DateField,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  CloneButton,
  FunctionField
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput
      source='name'
      parse={v => {
        return v && {
          $regex: v,
          $options: 'i'
        }
      }}
      format={v => v && v.$regex}
      alwaysOn
    />
    <ReferenceInput
      source='event'
      reference='events'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>
    <ReferenceInput
      source='eventGroup'
      reference='event-groups'
      sort={{
        field: 'name',
        order: 'ASC'
      }}
      filterToQuery={searchText => ({
        name: {
          $regex: searchText,
          $options: 'i'
        }
      })}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        optionText='name'
        emptyText='clear search'
      />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List
    {...props}
    filters={<CustomFilter />}
    sort={{
      field: 'createdAt',
      order: 'DESC'
    }}
  >
    <Datagrid>
      <TextField source='name' />
      <ReferenceField
        source='event'
        reference='events'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <ReferenceField
        source='eventGroup'
        reference='event-groups'
      >
        <TextField
          source='name'
        />
      </ReferenceField>
      <DateField source='dateFrom' />
      <DateField source='dateTo' />
      <TextField source='price' />
      <FunctionField
        label='Extensions'
        render={record => record.extensions ? record.extensions.length : 0}
      />
      <FunctionField
        label='Discounts'
        render={record => record.discounts ? record.discounts.length : 0}
      />
      <BooleanField source='open' />
      <BooleanField source='show' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <CloneButton />
      <EditButton />
    </Datagrid>
  </List>
)
