import React, { useMemo } from 'react'
import {
  AutocompleteInput,
  Create,
  DateInput,
  ListButton,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  TopToolbar,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput
} from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <ReferenceInput
          source='eventDate'
          reference='event-dates'
          sort={{
            field: 'name',
            order: 'ASC'
          }}
          filterToQuery={searchText => ({
            name: {
              $regex: searchText,
              $options: 'i'
            }
          })}
          validate={required()}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <TextInput source='email' type='email' validate={required()} />
        <TextInput source='pesel' validate={required()} />
        <TextInput source='firstName' validate={required()} />
        <TextInput source='lastName' validate={required()} />
        <DateInput source='dateOfBirth' validate={required()} />
        <TextInput source='address.city' />
        <TextInput source='address.postal' />
        <TextInput source='address.street' />
        <TextInput source='phone' validate={required()} />
        <TextInput source='secondPhone' />
        <TextInput source='tutor.name' />
        <TextInput source='tutor.phone' />
        <ArrayInput source='extensions'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' />
            <TextInput source='option' label='Option' />
            <TextInput source='price' label='Price' />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='discounts'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' />
            <TextInput source='price' label='Price' />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source='confirmed'
          helperText='Set true if you want the registration to be confirmed.'
        />
        <BooleanInput
          source='blockSignUpEmail'
          defaultValue
          helperText='If set true, the sign up confirmation email will not send.'
        />
      </SimpleForm>
    </Create>
  )
}
