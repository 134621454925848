import React, { useMemo } from 'react'
import { Create, required, SimpleForm, TopToolbar, ListButton, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <TextInput
          source='from'
          helperText='If not set, default value will be used.'
        />
        <TextInput source='to' validate={required()} />
        <TextInput source='subject' validate={required()} />
        <TextInput
          source='html'
          multiline
          fullWidth
          validate={required()}
          helperText='Body of the email.'
        />
        <ArrayInput source='attachments'>
          <SimpleFormIterator>
            <TextInput source='filename' />
            <TextInput source='path' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
