import { useDataProvider, useNotify } from 'react-admin'
import React, { useCallback, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'

const ConfirmButton = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const onClick = useCallback(async () => {
    try {
      setLoading(true)
      const res = await dataProvider.create('confirmation-emails', {
        data: {
          registration: props.record.id
        }
      })

      await dataProvider.getOne('registrations', {
        id: props.record._id
      })

      if (res.data.status === 'complete') {
        notify('Registration confirmed')
      } else {
        notify('Registration confirmation has FAILED', 'warning')
      }
    } catch (e) {
      console.log(e)
      notify(e.message, 'warning')
    } finally {
      setLoading(false)
    }
  }, [])

  if (props.record.confirmed) {
    return <CheckIcon />
  }
  return (
    <Button
      onClick={onClick}
    >
      {loading ? <CircularProgress size={20} /> : 'Confirm'}
    </Button>
  )
}

ConfirmButton.defaultProps = {
  addLabel: true,
  label: 'Confirmed'
}

export default ConfirmButton
