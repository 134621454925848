import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  ReferenceField,
  TopToolbar,
  required,
  DateField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  EditButton,
  UrlField,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import CreateExternalButton from '../../elements/CreateExternalButton'
import ConfirmButton from './ConfirmButton'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextField source='fullName' />
        <ReferenceField
          source='eventDate'
          reference='event-dates'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <ReferenceField
          source='event'
          reference='events'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <ReferenceField
          source='eventGroup'
          reference='event-groups'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <ConfirmButton />
        <TextField source='price' />
        <TextInput source='pesel' validate={required()} />
        <TextInput source='firstName' />
        <TextInput source='lastName' />
        <DateInput source='dateOfBirth' />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
      <FormTab label='contact'>
        <TextInput source='email' type='email' validate={required()} />
        <TextInput source='phone' />
        <TextInput source='secondPhone' />
        <TextInput source='address.city' />
        <TextInput source='address.postal' />
        <TextInput source='address.street' />
        <TextInput source='tutor.name' />
        <TextInput source='tutor.phone' />
      </FormTab>
      <FormTab label='agreements' path='agreements'>
        <TextField source='agreementVersion' />
        <CreateExternalButton
          reference='registration'
          service='agreements'
        >
          Create agreement
        </CreateExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='agreements'
          target='registration'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <TextField source='name' />
            <UrlField source='url' target='_blank' />
            <TextField source='status' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      <FormTab label='extensions' path='extensions'>
        <ArrayInput source='extensions'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' />
            <TextInput source='option' label='Option' />
            <TextInput source='price' label='Price' />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label='discounts' path='discounts'>
        <ArrayInput source='discounts'>
          <SimpleFormIterator>
            <TextInput source='name' label='Name' />
            <TextInput source='price' label='Price' />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='sign up emails' path='sign-up-emails'>
        <CreateExternalButton
          reference='registration'
          service='sign-up-emails'
        >
          Create sign up email
        </CreateExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='sign-up-emails'
          target='registration'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <ReferenceField
              source='email'
              reference='emails'
            >
              <TextField
                source='name'
              />
            </ReferenceField>
            <TextField source='status' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label='confirmation emails' path='confirmation-emails'>
        <CreateExternalButton
          reference='registration'
          service='confirmation-emails'
        >
          Create confirmation
        </CreateExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='confirmation-emails'
          target='registration'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <ReferenceField
              source='agreement'
              reference='agreements'
            >
              <TextField
                source='name'
              />
            </ReferenceField>
            <ReferenceField
              source='email'
              reference='emails'
            >
              <TextField
                source='name'
              />
            </ReferenceField>
            <TextField source='status' />
            <TextField source='agreementStatus' />
            <TextField source='emailStatus' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)
