import React from 'react'
import { Create, required, SimpleForm, TextInput, TopToolbar, ListButton } from 'react-admin'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Create
    {...props}
    actions={<Actions />}
  >
    <SimpleForm>
      <TextInput source='name' validate={required()} />
      <TextInput source='description' multiline fullWidth />
    </SimpleForm>
  </Create>
)
