import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  required,
  DateField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  EditButton,
  BooleanField
} from 'react-admin'
import CreateExternalButton from '../../elements/CreateExternalButton'
import ShowExternalButton from '../../elements/ShowExternalButton'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextInput source='name' validate={required()} />
        <TextInput source='description' multiline fullWidth />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
      <FormTab label='events' path='events'>
        <CreateExternalButton
          reference='eventGroup'
          service='events'
        >
          Create event
        </CreateExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='events'
          target='eventGroup'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <TextField source='name' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label='registrations' path='registrations'>
        <CreateExternalButton
          reference='eventGroup'
          service='registrations'
        >
          Create registration
        </CreateExternalButton>
        <ShowExternalButton
          reference='eventGroup'
          service='registrations'
        >
          Show details
        </ShowExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='registrations'
          target='eventGroup'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <BooleanField source='confirmed' />
            <TextField source='fullName' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)
