import React, { useMemo } from 'react'
import {
  BooleanInput,
  Create,
  ListButton,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  TopToolbar,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <ReferenceInput
          source='eventGroup'
          reference='event-groups'
          sort={{
            field: 'name',
            order: 'ASC'
          }}
          filterToQuery={searchText => ({
            name: {
              $regex: searchText,
              $options: 'i'
            }
          })}
          validate={required()}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <TextInput source='name' validate={required()} />
        <TextInput source='forWho' />
        <BooleanInput source='over18' />
        <TextInput source='duration' />
        <TextInput source='localization' />
        <TextInput source='description' multiline fullWidth />
        {/* TODO image */}
        <NumberInput source='advancePaymentPrice' validate={required()} />
        {/* TODO files */}
        {/* TODO extraInformation */}
      </SimpleForm>
    </Create>
  )
}
