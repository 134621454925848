import React, { useMemo } from 'react'
import { Create, required, SimpleForm, TopToolbar, ListButton, ReferenceInput, AutocompleteInput } from 'react-admin'
import { parse } from 'query-string'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => {
  const initialValues = useMemo(() => parse(props.location.search), [])

  return (
    <Create
      {...props}
      actions={<Actions />}
    >
      <SimpleForm
        initialValues={initialValues}
      >
        <ReferenceInput
          source='registration'
          reference='registrations'
          sort={{
            field: 'fullName',
            order: 'ASC'
          }}
          filterToQuery={searchText => ({
            fullName: {
              $regex: searchText,
              $options: 'i'
            }
          })}
          validate={required()}
        >
          <AutocompleteInput
            optionText='fullName'
            onChange={v => console.log('v', v)}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
