import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  NumberInput,
  required,
  DateField,
  BooleanInput,
  ReferenceManyField,
  Pagination,
  Datagrid,
  EditButton,
  ReferenceField,
  ArrayInput,
  SimpleFormIterator,
  BooleanField
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import ImageInput from '../../elements/ImageInput'
import CreateExternalButton from '../../elements/CreateExternalButton'
import ShowExternalButton from '../../elements/ShowExternalButton'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <ReferenceField
          source='eventGroup'
          reference='event-groups'
        >
          <TextField
            source='name'
          />
        </ReferenceField>
        <TextInput source='name' validate={required()} />
        <NumberInput source='advancePaymentPrice' validate={required()} />
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
      <FormTab label='details' path='details'>
        <BooleanInput source='over18' />
        <TextInput source='forWho' />
        <TextInput source='duration' />
        <TextInput source='localization' />
        <ImageInput source='image' />
        <RichTextInput source='description' multiline fullWidth />
        <ArrayInput source='extraInformation'>
          <SimpleFormIterator>
            <TextInput source='name' />
            <RichTextInput source='content' />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='files' path='files'>
        <ArrayInput source='files'>
          <SimpleFormIterator>
            <ImageInput
              source=''
              getInitialValue={record => record} // workaround for issue with array input
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label='event-dates' path='event-dates'>
        <CreateExternalButton
          reference='event'
          service='event-dates'
        >
          Create event date
        </CreateExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='event-dates'
          target='event'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <TextField source='name' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label='registrations' path='registrations'>
        <CreateExternalButton
          reference='event'
          service='registrations'
        >
          Create registration
        </CreateExternalButton>
        <ShowExternalButton
          reference='event'
          service='registrations'
        >
          Show details
        </ShowExternalButton>
        <ReferenceManyField
          addLabel={false}
          fullWidth
          reference='registrations'
          target='event'
          perPage={10}
          pagination={<Pagination />}
          sort={{
            field: 'createdAt',
            order: 'DESC'
          }}
        >
          <Datagrid>
            <BooleanField source='confirmed' />
            <TextField source='fullName' />
            <DateField source='createdAt' showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)
