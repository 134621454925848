import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TopToolbar,
  DateField,
  ArrayField,
  Datagrid,
  UrlField,
  Labeled
} from 'react-admin'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

const HtmlField = (props) => {
  return (
    <Labeled label={props.label} fullWidth>
      <div dangerouslySetInnerHTML={{ __html: props.record.html }} />
    </Labeled>
  )
}

export default (props) => (
  <Edit
    {...props}
    undoable={false}
    actions={<Actions />}
  >
    <TabbedForm
      redirect={false}
    >
      <FormTab label='summary'>
        <TextField source='_id' />
        <TextField source='name' />
        <TextField source='status' />
        <TextField source='errorMessage' />
        <TextField source='from' />
        <TextField source='to' />
        <TextField source='subject' />
        <HtmlField label='HTML' />
        <TextField source='text' />
        <ArrayField source='attachments'>
          <Datagrid>
            <TextField source='filename' />
            <UrlField source='path' target='_blank' />
          </Datagrid>
        </ArrayField>
        <DateField source='createdAt' showTime />
        <DateField source='updatedAt' showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)
