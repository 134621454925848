import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'

import { Loading, Error } from 'react-admin'

import app from './client/feathersClient'

const Dashboard = props => {
  const [stats, setStats] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    app.service('stats').find()
      .then((stats) => {
        setStats(stats)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [])

  if (loading) return <Loading />
  if (error) return <Error />
  if (!stats) return null

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          align='center'
          style={{
            marginTop: 20
          }}
        >
          <Typography variant='h5'>GSP: Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Rejestracje</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant='outlined'>
            <CardHeader
              title='Liczba niepotwierdzonych rejestracji'
              titleTypographyProps={{
                variant: 'caption',
                color: stats.notConfirmedRegistrations > 0 ? 'error' : 'initial'
              }}
              subheader={stats.notConfirmedRegistrations}
              subheaderTypographyProps={{
                variant: 'h5',
                color: stats.notConfirmedRegistrations > 0 ? 'error' : 'initial'
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant='outlined'>
            <CardHeader
              title='Całkowita liczba rejestracji'
              titleTypographyProps={{
                variant: 'caption'
              }}
              subheader={stats.allRegistrations}
              subheaderTypographyProps={{
                variant: 'h5'
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard
